<template>
  <div class="container">
    <nav-box title="自助开牌" :back="true"></nav-box>
    <div class="content flex-column">
      <div
        class="item flex flex-center"
        @click="selectSex(1)"
      >
        <!-- <div class="label flex flex-center" v-if="sex == 1">
          <van-icon name="success" size="18" color="#fff" />
        </div> -->
        <img class="image" src="@/assets/man.png" alt="" />
        <span>男宾</span>
      </div>
      <div
        class="item flex flex-center"
        @click="selectSex(2)"
      >
        <!-- <div class="label flex flex-center" v-if="sex == 2">
          <van-icon name="success" size="18" color="#fff" />
        </div> -->
        <img class="image" src="@/assets/woman.png" alt="" />
        <span>女宾</span>
      </div>
    </div>
  </div>
</template>

<script>
import navBox from "@/components/nav-box.vue";
export default {
  components: {
    navBox,
  },
  name: "openCard",
  data() {
    return {
      sex: 1,
    };
  },
  methods: {
    selectSex(index) {
      localStorage.setItem('sex',this.sex)
      this.sex = index;
      this.$router.push({
        path: "/codeBox",
        query: {
          sex: index,
        },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.content {
  align-items: center;
  justify-content: center;
  padding-top: 240px;
  .item {
    width: 858px;
    height: 260px;
    box-sizing: border-box;
    border: 3px solid #c7cad1;
    border-radius: 24px;
    margin-bottom: 120px;
    position: relative;
    .image {
      width: 200px;
      height: 200px;
    }
    span {
      font-size: 60px;
      color: #453d36;
      margin-left: 32px;
    }
    .label {
      width: 81px;
      height: 57px;
      border-radius: 12px 0;
      background-color: #0068f0;
      position: absolute;
      top: 0;
      left: 0;
    }
    &.on {
      border: 3px solid #0068f0;
      background-color: #ecf5ff;
    }
  }
}
</style>